<template>
  <div id="smp">
    <div id="header" class="flex f-ai">
      <p>{{ $t('password.change') }}</p>
    </div>
    <Card id="smp-card">
      <div class="flex f-ai f-column">
        <img src="@/assets/img/high-five.svg" />
        <strong>{{ $t('password.succesfully') }} </strong>
        <div>{{ $t('global.mayReturnToApp') }}</div>
      </div>
    </Card>
  </div>
</template>
<script>
import { Card } from '../components';
export default {
  components: {
    Card
  }
};
</script>

<style lang="sass" scoped>
#smp
  position: fixed
  overflow: auto
  top: 0
  left: 0
  height: 100%
  width: 100%
  background: $main-background
  #smp-card
    width: 90%
    max-width: 700px
    margin: 40px auto 10px
    text-align: center
    padding: 10px 10px 30px
    img
      width: 100%
      max-width: 500px
  #header
    width: 100%
    background: white
    padding: 20px
    font-weight: 700
    font-size: 22px
@media only screen and (max-width: 576px)
  #header
    max-height: 60px
</style>
